import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/about-us": [4],
		"/asset/[slug]": [5],
		"/blog/prusa-research-shaping-a-greener-future-despite-open-source-challenges": [~6],
		"/blog/what-is-3D-printer-firmware-marlin-vs-klipper": [7],
		"/compare/[slug]": [8],
		"/create_review/[slug]": [9],
		"/review/[slug]": [10],
		"/signup": [11],
		"/signup/success": [12],
		"/signup/verify/[id]": [~13,[],[2]],
		"/test": [14],
		"/update_review/[slug]": [15]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';